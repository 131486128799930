const create = (ref, data) => {
  return ref.add(data);
};

const update = (ref, id, data) => {
  return ref.doc(id).set(data);
};

const list = ref => {
  return ref.get();
};

const get = (ref, id) => {
  return ref.doc(id).get();
};

const deleteDoc = (ref, id) => {
  return ref.doc(id).delete();
};

export { create, list, get, update, deleteDoc };
