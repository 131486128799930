<template>
  <div>
    <div v-if="notFound">
      <v-alert type="error" text> Invalid Mailing List Id </v-alert>
    </div>
    <div v-if="error">
      <v-alert type="error" text>
        {{ error }}
      </v-alert>
    </div>
    <div v-if="emailList">
      <slot name="title"></slot>
      <div
        class="email-list"
        :style="{
          'max-height': maxHeight,
          'background-color': backgroundColor
        }"
        :class="{ outlined }"
      >
        <div v-for="email in sort(emailList)" :key="email" class="email-list-item">
          <mark v-if="highlightEmail === email">
            {{ email }}
          </mark>
          <span v-else>
            {{ email }}
          </span>
        </div>
      </div>
      <small>
        {{ emailsLength(emailList) }}
      </small>
    </div>
  </div>
</template>

<script>
import Email from '../../../services/email';
const email = new Email();
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    emails: {
      type: Array,
      default: null
    },
    highlightEmail: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: String,
      default: '200px'
    },
    outlined: { type: Boolean, default: false },
    backgroundColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      error: '',
      notFound: false,
      emailList: null
    };
  },
  watch: {
    emails: {
      handler(value) {
        if (value) {
          this.emailList = value;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    cmp(a, b) {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    },
    sort(arr) {
      const res = [...arr];
      res.sort(this.cmp);
      return res;
    },
    getData() {
      if (!this.id) {
        return;
      }
      this.error = '';
      this.notFound = false;
      email
        .getMailingList(this.id)
        .then(res => {
          if (res.exists) {
            this.emailList = res.data().emails;
            this.$emit('input', this.emailList);
          } else {
            this.notFound = true;
          }
        })
        .catch(error => {
          this.error = error.message;
        });
    },
    emailsLength(emails) {
      let res = `${emails.length} Email`;
      if (emails && emails.length) {
        if (emails.length > 1) {
          res = `${res}s`;
        }
        return res;
      } else {
        return 'No Emails';
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../design/_colors.scss';
.outlined {
  border: 1px solid $border-color;
  border-radius: 3px;
}
.email-list {
  overflow: scroll;
  padding: 3px;
}
.email-list-item {
  padding: 2px 0px 3px 0px;
  margin-left: 5px;
}
mark {
  // background-color: rgb(180, 180, 54);
  color: black;
}
</style>
