import { getFirestore, getCurrentTimestamp, timestamp } from './firebase';
import { create, list, deleteDoc, get } from './crud';

class Email {
  constructor() {
    this.ref = getFirestore().collection('email');
    this.types = {
      MAILING_LIST: 'MAILING_LIST',
      EMAIL_HISTORY: 'EMAIL_HISTORY',
      EMAIL_HTML: 'EMAIL_HTML'
    };
  }

  getTimestamp = timestamp;

  createMailingList(data) {
    const validatedData = {
      ...data,
      createdAt: getCurrentTimestamp(),
      updatedAt: getCurrentTimestamp(),
      type: this.types.MAILING_LIST
    };
    return this.ref.add(validatedData);
  }
  updateMailingList(id, data) {
    const validatedData = { ...data, updatedAt: getCurrentTimestamp() };
    return this.ref.doc(id).set(validatedData);
  }

  listMailingList({ email }, cb) {
    let query = this.ref.where('type', '==', this.types.MAILING_LIST).orderBy('updatedAt', 'desc');

    if (email) {
      query = query.where('emails', 'array-contains', email);
    }

    if (cb) {
      return query.onSnapshot(cb);
    }
    return query.get();
  }

  getMailingList(id) {
    return this.ref.doc(id).get();
  }

  deleteMailingList(id) {
    return this.ref.doc(id).delete();
  }

  createEmailHistory = data => {
    return create(this.ref, {
      ...data,
      type: this.types.EMAIL_HISTORY,
      ...this.getTimestamp()
    });
  };

  listEmailHistory = ({ email, searchEmailIn }) => {
    if (!searchEmailIn) {
      searchEmailIn = 'to';
    }
    let q = this.ref.orderBy('createdAt', 'desc').where('type', '==', this.types.EMAIL_HISTORY);
    if (email) {
      q = q.where(searchEmailIn, 'array-contains', email);
    }
    return list(q);
  };

  createEmailHtml(data) {
    const validatedData = {
      ...data,
      ...this.getTimestamp(),
      type: this.types.EMAIL_HTML
    };
    return create(this.ref, validatedData);
  }

  // listEmailHtml = () => {
  //   return list(this.ref.orderBy('createdAt', 'desc').where('type', '==', this.types.EMAIL_HTML));
  // };
  list = ({ type }, cb) => {
    let query = this.ref;

    query = query.orderBy('createdAt', 'desc');

    if (type) {
      query = query.where('type', '==', type);
    }

    if (cb) {
      return query.onSnapshot(cb);
    } else {
      return query.get();
    }
  };

  updateEmailHtml(id, data) {
    const validatedData = { ...data, updatedAt: getCurrentTimestamp() };
    return this.ref.doc(id).set(validatedData);
  }

  delete(id) {
    return deleteDoc(this.ref, id);
  }

  get(id) {
    return this.ref.doc(id).get();
  }
}
export default Email;
