<template>
  <div>
    <div v-if="loading">
      <div v-for="j in 3" :key="j" class="outlined--primary pa-2 mb-2">
        <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        <v-skeleton-loader v-for="i in 2" class="my-1" :key="i" type="text"></v-skeleton-loader>
      </div>
    </div>
    <div v-if="items">
      <v-alert
        type="info"
        class="my-3 mx-auto mt-5"
        outlined
        v-if="!loading && items.length === 0"
        max-width="600"
      >
        No email history found
      </v-alert>
      <div v-for="item in items" :key="item.id" class="outlined--primary pa-2 mb-2">
        <div>
          <div v-if="item.toList">
            <strong> To: </strong>
            <br />
            <ListEmail
              :emails="item.to"
              outlined
              :highlight-email="highlightEmail"
              backgroundColor="rgba(233, 243, 235, 0.274)"
              class="ml-3 mr-3"
            />
          </div>
          <div v-else>
            <strong>To: </strong>
            <span v-html="fmtEmails(item.to)"></span>
          </div>
          <div v-if="item.cc"><strong>Cc:</strong> <span v-html="fmtEmails(item.cc)"></span></div>
          <div v-if="item.bcc">
            <strong>Bcc:</strong> <span v-html="fmtEmails(item.bcc)"></span>
          </div>
          <div class="mt-3">
            <strong>Subject:</strong>
            {{ item.subject }}
          </div>
          <div>
            <strong>Body:</strong>
            <div class="ml-3">
              <div v-if="item.htmlId">
                <EmailHtmlDetail :id="item.htmlId" />
              </div>
              <div v-html="item.html"></div>
            </div>
          </div>
        </div>
        <div class="mt-4 mb-2">
          <DateHover
            :small-font="false"
            :date="item.createdAt.toDate()"
            bottom
            :label-attrs="{ class: 'label--text' }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Email from '../../../services/email';
import ListEmail from '../../../components/Admin/Mailing/ListEmail.vue';
const email = new Email();
import Moment from 'moment';
export default {
  components: {
    ListEmail,
    DateHover: () => import('../../../components/Core/DateHover.vue'),
    EmailHtmlDetail: () => import('../../../components/Admin/EmailHtml/Detail.vue')
  },
  props: {
    email: {
      type: String,
      default: ''
    },
    highlightEmail: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      items: null,
      loading: true
    };
  },
  created() {
    this.getData();
  },
  methods: {
    fmtEmails(e) {
      if (typeof e === 'string') {
        if (e === this.email && this.highlightEmail) {
          return `<mark>${e}</mark>`;
        } else {
          return e;
        }
      }
      let res = ``;
      e.forEach((i, index) => {
        if (i == this.email && this.highlightEmail) {
          res += `<mark>${i}</mark>`;
        } else {
          res += `${i}`;
        }
        if (index !== e.length - 1) {
          res += ', ';
        }
      });
      return res;
    },
    format(d, fmt) {
      return Moment(d).format(fmt);
    },
    async getData() {
      this.loading = true;
      const data = [];
      let res;

      try {
        if (this.email) {
          res = await email.listEmailHistory({ email: this.email, searchEmailIn: 'to' });
          res.forEach(i => {
            data.push({ ...i.data(), id: i.id });
          });

          res = await email.listEmailHistory({ email: this.email, searchEmailIn: 'cc' });
          res.forEach(i => {
            data.push({ ...i.data(), id: i.id });
          });

          res = await email.listEmailHistory({ email: this.email, searchEmailIn: 'bcc' });
          res.forEach(i => {
            data.push({ ...i.data(), id: i.id });
          });
        } else {
          res = await email.listEmailHistory({});
          res.forEach(i => {
            data.push({ ...i.data(), id: i.id });
          });
        }
      } catch (error) {
        console.warn(error);
      }

      this.items = data;
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import '../../../design/_colors.scss';
strong {
  color: $color-light-black !important;
}
mark {
  // background-color: rgb(180, 180, 54);
  background-color: red;
  color: black;
}
</style>
